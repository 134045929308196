<template>
  <div class="vg_wrapper">
    <el-card>
     <div class="vd_button_group vg_mtb_16">
      <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
      <!-- <el-button type="danger" size="small" @click="mBox"><i class="el-icon-delete"></i> 删除</el-button> -->
    </div>

     <el-dialog title="新增角色" :visible.sync="dialogFormVisible" width="500px">
      <el-form ref="dialogForm" :model="dialogForm" label-width="120px" size="mini" :show-message="false">
        <el-form-item label="角色名称">
          <el-input v-model="dialogForm.role_name" @input="dialogForm.role_name=helper.keepSpace(dialogForm.role_name)" maxlength="10" show-word-limit placeholder="请填写角色名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false;dialogForm.role_name = null;" size="small">取 消</el-button>
        <el-button type="primary" @click="submit('dialogForm')" size="small" :loading="btnFlag">确 定</el-button>
      </div>
    </el-dialog>

     <el-row>
      <el-col :md="24">
        <el-table v-loading="loadFlag" ref="multiTable" :model="roleForm" :data="tableData" class="vd_table vg_cursor"  @row-dblclick="dbClickJp" border>
          <!-- <el-table-column type="selection" width="48"  align="center"/> @selection-change="handleSelectionChange"-->
          <el-table-column label="角色名称" :show-overflow-tooltip='true' prop="role_name" />
          <el-table-column label="角色使用" :show-overflow-tooltip='true' align="center">
            <template slot-scope="scope">
             <el-tag type="success" v-if="scope.row.role_status==1">开启</el-tag>
             <el-tag type="info" v-if="scope.row.role_status==0">关闭</el-tag>
           </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {roleAPI} from "@api/modules/role";
export default {
  name: 'RoleList',
  components: {
  },
  data() {
    return {
      roleForm: {
        role_name:  null,
      },
      dialogForm: {
        role_name: '',
      },
      dialogFormVisible: false,
      tableData: [],
      multiSelection: [],
      btn:{},
      btnFlag:false,
      loadFlag:true
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/role_edit'){
        this.initData()
      }
    }
  },
  methods: {
    initData(){
      this.getRoles();
    },
    // 获取角色信息
    getRoles() {
      get(roleAPI.getAllRoles)
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            console.log(res);
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 多选获取部门信息
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 新增
    addRow(){ 
      this.dialogFormVisible = true
    },
    //删除提示
    mBox() {
      if(this.multiSelection.length===0){
        this.$message.warning('最少选择一名角色')
        return 
      }
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning' 
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.role_id)
      })
      post(roleAPI.destroyRoleByIds,{'role_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              if(ids.length === this.tableData.length){
                this.currentPage = this.currentPage -1
              }
              this.getRoles();
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.getRoles();
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      this.jump('/role_edit',{perm_id:this.$route.query.perm_id,form_id:row.role_id})
    },
    //提交表单
    submit(formName){
      this.$nextTick(()=>{
        this.btnFlag = true
      })
      setTimeout(() => {
        if(this.dialogForm.role_name){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                this.dialogFormVisible = false
                this.saveInfo()
              } else {
                this.btnFlag = false;
                console.log('error submit!');
                return false;
              }
            });
        }else{
          this.$message({
            type: 'warning',
            message: '不能输入为空'
          });
          this.btnFlag = false
        }
      }, 500);
    },
    // 保存
    saveInfo() {
      post(roleAPI.addRole,this.dialogForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              this.dialogFormVisible = false
              this.dialogForm.role_name = ''
              this.getRoles();
              this.btnFlag = false
              const permId = this.$route.query.perm_id
              this.jump('role_edit',{perm_id:permId,form_id:res.data.data.form_id})
              console.log('formid',res.data.data);
            }else if(res.data.code === 7){
              this.$message({
                type: 'error',
                message: '该角色名称已存在',
              });
              this.dialogFormVisible = false
              this.dialogForm.role_name = ''
              this.getRoles();
              this.btnFlag = false
            }else{
              this.$message({
                type: 'error',
                message: res.data.msg,
              });
              this.dialogFormVisible = false
              this.dialogForm.role_name = ''
              this.getRoles();
              this.btnFlag = false
              
            }
          }).catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
  }
};
</script>

<style scoped lang="scss">
.vd_table{
  width: 20%;
}
</style>